async function checkUrl(url) {
  let result = document.getElementById('result')

  if (url.length === 0) {
    return (result.innerHTML = 'please provide an URL to check')
  }
  if (!url.startsWith('http')) {
    return `http://${url}`
  }
  return url
}

async function submitUrl() {
  let form = document.getElementById('form')
  form.addEventListener('submit', (e) => {
    e.preventDefault()
  })

  let result = document.getElementById('result')
  let input = document.getElementById('url').value

  input = await checkUrl(input)

  fetch(`https://wheretoapi.ilayk.com/?${input}`)
    .then((resp) => resp.json())
    .then((data) => {
      if (data.success) {
        result.innerHTML = ''
        let link = document.createElement('a')
        link.href = data.url
        link.classList = 'text-blue-500'
        link.innerText = data.url
        result.appendChild(link)
      } else {
        result.innerHTML = ''
        let err = document.createElement('div')
        if (typeof data === 'object' && data.message.status) {
          err.innerText = `encountered an error: ${data.message.status} - ${data.message.statusText}`
        } else {
          err.innerText = `encountered an error: ${data.message}`
        }
        result.appendChild(err)
      }
    })
}
